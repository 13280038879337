import React from 'react'
import { graphql, navigate, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import ArticleList from '../components/home/article-list'

const Articles = ({ data, location }) => (
  <ArticleList
    title="Home"
    seo={{ title: 'Daseveny' }}
    barTitle="អត្ថបទ​ទាំងអស់"
    location={location}
    data={data}
    pageNavigate={(pageNum) => navigate(`/${pageNum}`)}
  />
)

const Index = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            filter: { frontmatter: { published: { eq: true } } }
            sort: { fields: [frontmatter___date], order: DESC }
            limit: 15
          ) {
            edges {
              node {
                excerpt(pruneLength: 625, truncate: true)
                fields {
                  slug
                  readingTime {
                    minutes
                  }
                }
                frontmatter {
                  date(formatString: "MMMM DD, YYYY")
                  title
                  author
                  featuredImage {
                    id
                    publicURL
                    base
                  }
                  tags
                }
              }
            }
            pageInfo {
              currentPage
              hasNextPage
              hasPreviousPage
              itemCount
              pageCount
              perPage
            }
            totalCount
          }
          allFile(filter: { ext: { ne: ".md" } }) {
            edges {
              node {
                id
                base
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 108, height: 108)
                }
              }
            }
          }
          allPageViews {
            nodes {
              id
              totalCount
            }
          }
          site {
            siteMetadata {
              authors {
                name
                nameInKhmer
                id
              }
            }
          }
        }
      `}
      render={(data) => <Articles data={data} {...props} />}
    />
  )
}

Articles.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            excerpt: PropTypes.string,
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
              readingTime: PropTypes.shape({
                minutes: PropTypes.number.isRequired
              }).isRequired
            }),
            frontmatter: PropTypes.shape({
              date: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired,
              author: PropTypes.number.isRequired,
              featuredImage: PropTypes.shape({
                id: PropTypes.string.isRequired,
                publicURL: PropTypes.string.isRequired,
                base: PropTypes.string.isRequired
              }),
              tags: PropTypes.array.isRequired
            }).isRequired
          })
        })
      ),
      pageInfo: PropTypes.shape({
        currentPage: PropTypes.number,
        hasNextPage: PropTypes.bool,
        hasPreviousPage: PropTypes.bool,
        itemCount: PropTypes.number,
        pageCount: PropTypes.number,
        perPage: PropTypes.number
      })
    }),
    allFile: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string.isRequired,
            base: PropTypes.string.isRequired,
            childImageSharp: PropTypes.any
          })
        })
      )
    }),
    allPageViews: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired
        })
      )
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        authors: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string
          })
        )
      })
    })
  })
}

export default Index
