import React from 'react'
import { navigate, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Tags from './post/tags'
import { getDateInKhmer, formatExcerpt } from '../utils'

const DetailCard = ({ data }) => {
  const { node, allFile } = data
  const featured = node.frontmatter.featuredImage
  const coverImage = featured ? featured.base : ''

  let thumbnail = null
  if (coverImage) {
    thumbnail = allFile.edges.find((edge) => edge.node.base === coverImage)
  } else {
    thumbnail = allFile.edges.find(
      (edge) => edge.node.base === 'new-default-cover.png'
    )
  }

  const readTime = Math.ceil(node.fields.readingTime.minutes)
  const tags = node.frontmatter.tags

  const excerptObj = formatExcerpt(node.excerpt)

  return (
    <div
      className="w-full mb-4 px-4 md:px-6 pt-4 md:pt-6 pb-0 md:pb-2 border-2 rounded-md"
      style={{
        color: 'var(--textNormal)',
        borderColor: 'var(--textNormal)',
        transition: 'color 0.2s ease-out, background 0.2s ease-out'
      }}
    >
      <div className="flex pt-1 md:pt-0 mb-2">
        <div
          className={`cursor-pointer text-xl font-bold hover:text-red-400`}
          onClick={() => navigate(node.fields.slug)}
        >
          {node.frontmatter.title}
        </div>
      </div>

      <div
        className={`hidden md:flex w-full mb-2 md:mb-2 border-0 border-b-2`}
        style={{
          borderColor: 'var(--textNormal)',
          transition: 'borderColor 0.2s ease-out, background 0.2s ease-out'
        }}
      ></div>

      <div className="flex flex-row pt-1 pb-2 mb-2">
        <p className={`text-sm mr-4`}>
          ថ្ងៃទី៖
          <span className="text-indigo-600 ml-2">
            {getDateInKhmer(node.frontmatter.date)}
          </span>
        </p>
        <p className={`text-sm`}>
          ប្រវែង៖
          <span className="text-indigo-600 ml-2">{readTime} នាទី</span>
        </p>
      </div>

      <div>
        <div
          className="relative float-left p-0.25 border-2 rounded-lg mr-4 mb-1 mt-2 w-20 h-20 z-100"
          style={{ borderColor: 'var(--textNormal)' }}
        >
          <GatsbyImage
            className="rounded-img-d ml-0.01"
            imgClassName="rounded-img-d"
            image={getImage(thumbnail.node)}
            alt="Detail's card image"
            objectFit="cover"
            objectPosition="center center"
          />
        </div>
        <p className="mb-4" style={{ fontSize: '1rem', lineHeight: 1.6 }}>
          {excerptObj.p1}
        </p>
        <p style={{ fontSize: '1rem', lineHeight: 1.6 }}>{excerptObj.p2}</p>
      </div>

      <div className="flex w-full mt-4">
        <Tags tags={tags} />
      </div>

      <div
        className={`w-full mb-2 md:mb-2 border-0 border-b-2`}
        style={{
          borderColor: 'var(--textNormal)',
          transition: 'borderColor 0.2s ease-out, background 0.2s ease-out'
        }}
      ></div>

      <div className="flex justify-center w-full mb-3 md:mb-1">
        <Link
          className={`text-sm shadow-none hover:text-red-400`}
          to={node.fields.slug}
        >
          អានបន្ត
        </Link>
      </div>
    </div>
  )
}

export default DetailCard
