import React from 'react'

const renderItems = (total, perPage, current, load) => {
  const items = []
  for (let i = total; i > 0; i -= perPage) {
    const index = items.length + 1
    items.push(
      <div
        className={`flex justify-center items-center h-7 text-sm mr-2 shadow-md rounded-md ${
          current === index
            ? 'bg-red-400 text-white'
            : 'hover:text-red-400 cursor-pointer'
        }`}
        style={{
          width: '1.70rem',
          color: current === index ? '#fff' : 'var(--textNormal)',
          backgroundColor: current === index ? '#fc8181' : 'var(--bg)',
          transition: 'color 0.2s ease-out, background 0.2s ease-out'
        }}
        key={i}
        onClick={current === index ? () => {} : () => load(index)}
      >
        {index}
      </div>
    )
  }
  return <div className="flex">{items}</div>
}

const Pagination = (props) => {
  const { current, total, perPage, hasPrev, hasNext, load, styleP } = props
  return (
    <div className={`flex w-full justify-center items-center ${styleP}`}>
      <div
        className={`mr-2 h-7 p-1 shadow-md rounded-md ${
          hasPrev ? 'hover:text-red-400 cursor-pointer' : ''
        }`}
        style={{
          width: '1.70rem',
          color: hasPrev ? 'var(--textNormal)' : 'var(--textMute)',
          backgroundColor: hasPrev ? 'var(--bg)' : 'var(--bgMute)',
          transition: 'color 0.2s ease-out, background 0.2s ease-out'
        }}
        onClick={hasPrev ? () => load(current - 1) : () => {}}
      >
        <svg
          className={`fill-current`}
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Previous</title>
          <path d="M7.05 9.293L6.343 10 12 15.657l1.414-1.414L9.172 10l4.242-4.243L12 4.343z" />
        </svg>
      </div>
      {renderItems(total, perPage, current, load)}
      <div
        className={`h-7 p-1 shadow-md rounded-md ${
          hasNext ? 'hover:text-red-400 cursor-pointer' : ''
        }`}
        style={{
          width: '1.70rem',
          color: hasNext ? 'var(--textNormal)' : 'var(--textMute)',
          backgroundColor: hasNext ? 'var(--bg)' : 'var(--bgMute)',
          transition: 'color 0.2s ease-out, background 0.2s ease-out'
        }}
        onClick={hasNext ? () => load(current + 1) : () => {}}
      >
        <svg
          className={`fill-current`}
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Next</title>
          <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
        </svg>
      </div>
    </div>
  )
}

export default Pagination
