import React from 'react'
import Layout from '../../layouts/layout'
import SEO from '../seo'
import TitleBar from '../title-bar'
import DetailCard from '../detail-card'
import Pagination from '../pagination'

const ArticleList = ({
  title,
  seo,
  barTitle,
  data,
  location,
  pageNavigate
}) => {
  const posts = data.allMarkdownRemark.edges
  const counts = data.allMarkdownRemark.totalCount
  const allFile = data.allFile
  const site = data.site
  const { currentPage, hasNextPage, hasPreviousPage, perPage } =
    data.allMarkdownRemark.pageInfo

  return (
    <Layout location={location} title={title}>
      <SEO {...seo} />
      <div
        className={`min-h-full shadow-md pt-4 md:pt-10 px-2 md:px-10 pb-1 md:pb-6 mb-1 md:mb-2`}
        style={{
          backgroundColor: 'var(--bg-card)',
          transition: 'color 0.2s ease-out, background 0.2s ease-out'
        }}
      >
        <TitleBar title={barTitle} location={location} />
        <div
          className={`flex flex-col md:flex-row justify-start flex-wrap ${
            hasNextPage ? '' : 'mb-1 md:mb-2'
          }`}
        >
          {posts.map(({ node }, i) => {
            return <DetailCard data={{ node, allFile, site }} key={i} />
          })}
        </div>

        <Pagination
          current={currentPage}
          total={counts}
          perPage={perPage}
          hasPrev={hasPreviousPage}
          hasNext={hasNextPage}
          load={(pageNum) => pageNavigate(pageNum)}
          styleP="mt-4 mb-6"
        />
      </div>
    </Layout>
  )
}

export default ArticleList
